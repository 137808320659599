<template>
  <div class="row no-wrap full-height q-pt-sm">
    <q-scroll-area
      :thumb-style="leftThumbScroll"
      :bar-style="leftBarScroll"
      style="height: 100%"
      class="col"
    >
      <div class="row">
        <div class="col-12 bg-white border-radius-4 border-box q-pa-sm">
          <div class="column">
            <q-item-label
              caption
              class="col-auto q-pb-xs"
            >
              {{ docLabelDescription }}
            </q-item-label>

            <div class="col q-px-sm">
              {{ doc.description }}
            </div>
          </div>
        </div>
        <div
          v-if="doc.documentTypeId === materiaDeInternet"
          class="col-12 bg-white border-radius-4 border-box q-pa-sm q-mt-sm"
        >
          <div class="column">
            <q-item-label
              caption
              class="col-auto q-pb-xs"
            >
              Link da matéria
            </q-item-label>

            <div class="col">
              <div
                class="ellipsis-3-lines text-primary q-px-sm"
                @click="copyLink(doc.url)"
              >
                <q-icon
                  size="1em"
                  name="mdi-content-copy"
                  class="cursor-pointer"
                >
                  <q-tooltip>Clique aqui para copiar o link</q-tooltip>
                </q-icon> {{ doc.url }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 bg-white border-radius-4 border-box q-pa-sm q-mt-sm">
          <div class="column">
            <q-item-label
              caption
              class="col-auto q-pb-xs"
            >
              Link para Divulgação
            </q-item-label>

            <div class="col">
              <div
                class="ellipsis-3-lines text-primary q-px-sm"
                @click="copyLink(link)"
              >
                <q-icon
                  size="1em"
                  name="mdi-content-copy"
                  class="cursor-pointer"
                >
                  <q-tooltip>Clique aqui para copiar o link</q-tooltip>
                </q-icon> {{ link }}
              </div>
            </div>
          </div>
        </div>

        <template v-if="doc.scheduleAt">
          <div class="col-12 bg-white border-radius-4 border-box q-pa-sm q-mt-sm">
            <div class="column">
              <q-item-label
                caption
                class="col-auto q-pb-xs"
              >
                Título da Divulgação
              </q-item-label>

              <div class="col q-px-sm">
                {{ doc.scheduleTitle }}
              </div>
            </div>
          </div>
        </template>

        <template v-if="doc.scheduleAt">
          <div class="col-12 bg-white border-radius-4 border-box q-pa-sm q-mt-sm">
            <div class="column">
              <q-item-label
                caption
                class="col-auto q-pb-xs"
              >
                Post da Divulgação
              </q-item-label>

              <div class="col q-pa-sm">
                <span
                  v-if="doc.scheduleResume"
                  v-html="doc.scheduleResume"
                />
                <span
                  v-else
                  class="bold text-red"
                >O POST NÃO FOI MONTADO!</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </q-scroll-area>

    <q-scroll-area
      visible
      :thumb-style="rightThumbScroll"
      :bar-style="rightBarScroll"
      style="height: 100%"
      class="col-4 q-pl-sm"
    >
      <q-list>
        <q-item
          class="bg-white border-radius-4 border-box q-px-sm"
          style="min-height: 57px"
        >
          <q-item-section>
            <q-item-label caption>
              Agendamento
            </q-item-label>

            <q-item-label>
              <div class="row items-center">
                <div class="col q-pl-sm">
                  {{ scheduledAt }}
                </div>
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>

        <template v-for="badge in badges">
          <q-item
            :key="badge.label"
            class="bg-white border-radius-4 border-box q-mt-sm q-px-sm"
            style="min-height: 57.7px"
          >
            <q-item-section>
              <q-item-label caption>
                {{ badge.label }}
              </q-item-label>

              <q-item-label class="q-pl-sm">
                <div v-html="badge.value" />
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <q-item
          class="bg-white border-radius-4 border-box q-mt-sm q-px-sm"
          v-if="images.length"
        >
          <q-item-section>
            <q-item-label caption>
              Imagens
            </q-item-label>

            <FieldImages
              readonly
              size="banner_315_150"
              :images="images"
            />
          </q-item-section>
        </q-item>

        <q-item
          class="bg-white border-radius-4 border-box q-mt-sm q-px-sm"
          v-if="attachments.length"
        >
          <q-item-section>
            <q-item-label caption>
              Anexos
            </q-item-label>

            <q-item-label>
              <FieldAttachments
                readonly
                size="banner_315_150"
                :value="attachments"
              />
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>

<script>
import moment from '@/components/moment'
import FieldImages from '@/components/forms/FieldImages'
import FieldAttachments from '@/components/forms/FieldAttachments'

import {
  genUrl,

  leftBarScroll,
  rightBarScroll,
  leftThumbScroll,
  rightThumbScroll,

  definedLabelSubType,
  definedLabelDescription
} from '@/components/documents'
import { MATERIA_DE_INTERNET } from '../constants'
export default {
  components: {
    FieldImages,
    FieldAttachments
  },

  props: {
    doc: {}
  },

  data () {
    return {
      leftBarScroll,
      rightBarScroll,
      leftThumbScroll,
      rightThumbScroll,
      materiaDeInternet: MATERIA_DE_INTERNET
    }
  },

  computed: {
    docLabelDescription () {
      return definedLabelDescription(this.doc)
    },

    scheduledAt () {
      const scheduleAt = this.doc?.scheduleAt ? toDateTime(this.doc.scheduleAt) : ''
      return scheduleAt || 'Sem Agendamento'
    },

    attachments () {
      const doc = this.doc
      return Array.isArray(doc?.attachments) ? doc.attachments : []
    },

    boardCouncil () {
      const doc = this.doc
      return Array.isArray(doc?.boardCouncil) ? doc.boardCouncil : []
    },

    images () {
      const doc = this.doc
      return Array.isArray(doc?.images) ? doc.images : []
    },

    roles () {
      const doc = this.doc
      return Array.isArray(doc?.roles) ? doc.roles : []
    },

    keywords () {
      const doc = this.doc
      return Array.isArray(doc?.keywords) ? doc.keywords : []
    },

    badges () {
      const vm = this
      const doc = vm.doc
      const badges = []

      badges.push({ label: 'Documento', value: doc.documentTypeName })
      if (doc.documentSubTypeId) badges.push({ label: definedLabelSubType(doc), value: doc.documentSubTypeName })

      badges.push({ label: 'Órgão Emissor', value: doc.agencyName })
      badges.push({ label: 'Grau do Conteúdo', value: doc.degreeName })

      const boardCouncil = vm.boardCouncil.map(({ prefix }) => prefix)
      if (boardCouncil.length) badges.push({ label: 'Reservado aos Colegiados', value: boardCouncil.join(', ') })

      const roles = vm.roles.map(({ name }) => name)
      if (roles.length) badges.push({ label: 'Cargos com Acesso', value: roles.join('<br />') })

      const keywords = vm.keywords.map(({ word }) => word)
      if (keywords.length) badges.push({ label: 'Palavras Chaves', value: keywords.join('<br />') })

      return badges
    },

    link () {
      return genUrl(this.doc)
    }
  },

  methods: {
    copyLink (url) {
      this.$copyText(url)
    }
  }
}

const toDateTime = (timestamp) => {
  if (!timestamp || isNaN(timestamp)) return ''
  const mDate = moment.unix(Number(timestamp))
  return mDate.isValid() ? mDate.tz('America/Sao_Paulo').format('DD/MM/YY HH:mm') : ''
}

</script>

<style lang="sass" scoped>
  .title
    font-size: 1.6em
    font-weight: bold

  .datetime
    font-size: 0.8em

  .observation
    font-size: 1.2em
</style>
