<template>
  <q-dialog
    v-model="confirm"
    persistent
  >
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">{{ modalMessage }}</span>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          label="Não"
          color="primary"
          v-close-popup
        />
        <q-btn
          flat
          label="Sim"
          color="primary"
          v-close-popup
          @click="execute"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    modalMessage: { type: String, required: true },
    handler: Function
  },
  data () {
    return {
      confirm: false
    }
  },
  methods: {
    execute () {
      if (this.handler) {
        this.handler()
      }
    },
    show () {
      this.confirm = true
    }
  }
}
</script>

<style>

</style>
