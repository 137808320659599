<template>
  <q-page :style-fn="getAreaDimension">
    <div
      class="column view-render-class"
      :style="`${areaHeight ? `height: ${areaHeight}px` : ''}`"
    >
      <DialogConfirm
        ref="dialogConfirm"
        :modal-message="confirmMessage"
        :handler="removeDoc"
      />

      <DialogAlert
        ref="dialogAlert"
        :alert-msg="alertMsg"
        :handler="redirect"
      />

      <div class="col-auto">
        <div class="row">
          <div class="col">
            <LayoutBreadcrumb
              :show-back-button="true"
              :metadata="document"
            >
              <div
                class="row q-gutter-md items-center"
                v-if="isAdmin && editLink"
              >
                <q-btn
                  flat
                  dense
                  class="col-auto btn-view"
                  color="negative"
                  @click="$refs.dialogConfirm.show()"
                >
                  <FileRemoveIcon />
                  <q-tooltip
                    content-class="bg-negative text-bold"
                    content-style="font-size: 12px"
                  >
                    Excluir Publicação
                  </q-tooltip>
                </q-btn>

                <q-btn
                  flat
                  dense
                  color="green-8"
                  class="col-auto btn-view"
                  :to="editLink"
                >
                  <FileEditIcon />
                  <q-tooltip
                    content-class="bg-green-8 text-bold"
                    content-style="font-size: 12px"
                  >
                    <span>Alterar Publicação</span>
                  </q-tooltip>
                </q-btn>

                <q-btn
                  flat
                  dense
                  color="primary"
                  class="col-auto btn-view"
                  :to="addLink"
                >
                  <FileAddIcon />
                  <q-tooltip
                    content-class="bg-primary text-bold"
                    content-style="font-size: 12px"
                  >
                    Adicionar Publicação
                  </q-tooltip>
                </q-btn>
              </div>
            </LayoutBreadcrumb>
          </div>
        </div>
      </div>

      <div class="col max-container-width q-mx-auto">
        <DocRender
          v-if="document"
          :doc="document"
        />
      </div>
    </div>

    <q-inner-loading
      :showing="isLoading"
      style="z-index: 9999"
    >
      <q-spinner-ios
        size="70px"
        color="primary"
      />
    </q-inner-loading>
  </q-page>
</template>

<script>
import api from '@/aws/api'
import { mapGetters } from 'vuex'
import DocRender from '../components/DocRender'
import LayoutBreadcrumb from '@/components/layouts/LayoutBreadcrumb'
import DialogConfirm from '@/components/modal/DialogConfirm'
import DialogAlert from '@/components/modal/DialogAlert'
import FileAddIcon from '@/assets/icons/FileAdd.svg'
import FileEditIcon from '@/assets/icons/FileEdit.svg'
import FileRemoveIcon from '@/assets/icons/FileRemove.svg'

import {
  ROUTE_DOCUMENTS,
  ROUTE_DOCUMENT_EDIT,
  ROUTE_DOCUMENT_CREATE,
  MATERIA_DE_INTERNET
} from '../constants'

export default {
  components: {
    FileAddIcon,
    FileEditIcon,
    FileRemoveIcon,

    DocRender,
    DialogAlert,
    DialogConfirm,
    LayoutBreadcrumb
  },

  data () {
    return {
      document: null,
      editLink: null,
      isLoading: true,
      areaHeight: null,
      addLink: { name: ROUTE_DOCUMENT_CREATE },
      confirmMessage: 'Tem certeza que deseja remover esse documento ?',
      alertMsg: '',
      materiaDeInternet: MATERIA_DE_INTERNET
    }
  },

  mounted () {
    const vm = this
    const isAdmin = vm.$store.getters.isAdmin
    if (!isAdmin) return vm.$router.go(-1)

    api.getDocument(vm.$route.params.id).then((document) => {
      vm.document = document
      vm.isLoading = false
      vm.editLink = { name: ROUTE_DOCUMENT_EDIT, params: { id: document.id } }
    })
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  },

  methods: {
    getAreaDimension (offset, height) {
      this.areaHeight = height - offset
    },
    redirect () {
      this.$router.push({ name: ROUTE_DOCUMENTS })
    },
    removeDoc () {
      const vm = this
      vm.isLoading = true

      api.delete(`/documents/${vm.document.id}`).then(() => {
        vm.isLoading = false
        vm.alertMsg = 'Documento removido com sucesso'
        vm.$refs.dialogAlert.show()
        setTimeout(() => vm.redirect(), 5000)
      }).catch(err => {
        vm.isLoading = false
        vm.alertMsg = err.message
        vm.$refs.dialogAlert.show()
      })
    }
  }
}
</script>
