<template>
  <q-dialog v-model="alert">
    <q-card>
      <q-card-section>
        <div class="text-h6">
          Informação
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ alertMsg }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          flat
          label="OK"
          color="primary"
          v-close-popup
          @click="execute"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: { alertMsg: { type: String, required: true }, handler: Function },
  data () {
    return {
      alert: false
    }
  },
  methods: {
    execute () {
      if (this.handler) {
        this.handler()
      }
    },
    show () {
      this.alert = true
    }
  }
}
</script>

<style>

</style>
