import { render, staticRenderFns } from "./DocRender.vue?vue&type=template&id=17e72ec1&scoped=true&"
import script from "./DocRender.vue?vue&type=script&lang=js&"
export * from "./DocRender.vue?vue&type=script&lang=js&"
import style0 from "./DocRender.vue?vue&type=style&index=0&id=17e72ec1&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17e72ec1",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QItemLabel,QIcon,QTooltip,QList,QItem,QItemSection});
